import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home-search-result" }
const _hoisted_2 = { class: "text-center my-60" }
const _hoisted_3 = {
  key: 0,
  class: "text-right mt-32"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_VideoGrid = _resolveComponent("VideoGrid")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VideoGrid, {
      "load-more-loading": _ctx.fetchMoreLoading,
      loading: _ctx.loading,
      "video-ideas": _ctx.results,
      "has-next-page": _ctx.hasNextPage,
      onOnItemClick: _ctx.handleVideoSelect,
      onOnLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-load-more'))),
      autoLoadMore: _ctx.autoLoadMore
    }, {
      empty: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_typography_title, {
            level: 2,
            class: "home-search-result__empty-title"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Oops! We are definitely a work in progress.")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_typography_paragraph, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t(`We don't have template videos for your product
        yet. But we can definitely work on it! Send us a message and let us know
        about your brand!`)), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["load-more-loading", "loading", "video-ideas", "has-next-page", "onOnItemClick", "autoLoadMore"]),
    (!_ctx.resultEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_a_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollToTop())),
            size: 'small'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Back to Top")), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}