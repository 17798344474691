import { useQuery } from "@vue/apollo-composable";
import { popularVideoIdeasGql } from "@/api/video/popularVideoIdeas";
import {
  PopularVideoIdeas,
  PopularVideoIdeas_popularVideoIdeas_PopularVideoIdeasResults,
} from "@/api/video/__generated__/PopularVideoIdeas";
import { parseGqlResponse } from "../utils/graphql/responseParser";
import { computed } from "vue";

export const usePopularVideoIdeas = () => {
  const {
    result: popularVideoIdeasResult,
    loading: popularVideoIdeasLoading,
    refetch: popularVideoIdeasRefetch,
  } = useQuery<PopularVideoIdeas>(
    popularVideoIdeasGql,
    {},
    { fetchPolicy: "network-only" }
  );

  const videoIdeasParsedData = computed(() => {
    return parseGqlResponse<PopularVideoIdeas_popularVideoIdeas_PopularVideoIdeasResults>(
      "PopularVideoIdeasResults",
      popularVideoIdeasResult.value
    ).data;
  });

  const popularVideoIdeas = computed(
    () => videoIdeasParsedData.value?.results ?? []
  );

  return {
    popularVideoIdeas,
    popularVideoIdeasLoading,
    popularVideoIdeasRefetch,
  };
};
