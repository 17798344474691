import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "button-select-carousel__option-icon" }
const _hoisted_2 = {
  key: 0,
  class: "button-select-carousel__active-item-extra"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicAppIcon = _resolveComponent("DynamicAppIcon")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", {
    key: _ctx.option.value,
    class: _normalizeClass({
      'button-select-carousel__option flex cursor-pointer': true,
      active: _ctx.selectedValue == _ctx.option.value,
    }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-select-option', _ctx.option.value)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.option.iconName)
        ? (_openBlock(), _createBlock(_component_DynamicAppIcon, {
            key: 0,
            name: _ctx.option.iconName,
            preview: false,
            fallbackName: "video_ad"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_typography_text, { class: "button-select-carousel__option-text small font-semibold" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.option.title), 1)
      ]),
      _: 1
    }),
    (!!_ctx.$slots['activeItemExtra'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.selectedValue == _ctx.option.value)
            ? _renderSlot(_ctx.$slots, "activeItemExtra", { key: 0 })
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}