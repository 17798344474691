
import { defineComponent, PropType, computed } from "vue";
import { useI18n } from "vue-i18n";
import isEmpty from "lodash/isEmpty";
import { VideoIdeas_videoIdeas_VideoIdeaResults_results } from "@/api/video/__generated__/VideoIdeas";
import { scrollToTop } from "@/shared/utils/browser";
import VideoGrid from "@/shared/components/Videos/VideoGrid.vue";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";

export default defineComponent({
  emits: ["on-load-more"],
  props: {
    loading: Boolean,
    fetchMoreLoading: Boolean,
    hasNextPage: Boolean,
    results: Array as PropType<
      VideoIdeas_videoIdeas_VideoIdeaResults_results[]
    >,
    itemPerPage: Number,
    autoLoadMore: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const { redirectToVideoDetails } = useRedirectHelper();

    const handleVideoSelect = (id) => {
      redirectToVideoDetails({ videoId: id });
    };

    return {
      t,
      handleVideoSelect,
      resultEmpty: computed(
        () =>
          !props.loading && !props.fetchMoreLoading && isEmpty(props.results)
      ),
    };
  },
  methods: { scrollToTop },
  components: { VideoGrid },
});
