
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  nextTick,
  watchEffect,
  computed,
  watch,
} from "vue";
import ButtonSelectCarouselOption, {
  ButtonSelectCarouselOptionData,
} from "@/shared/components/ButtonSelectCarousel/ButtonSelectCarouselOption.vue";
import { useI18n } from "vue-i18n";
import RightOutlined from "@ant-design/icons-vue/RightOutlined";
import LeftOutlined from "@ant-design/icons-vue/LeftOutlined";
import { useVModel } from "vue-composable";
import throttle from "lodash/throttle";

export default defineComponent({
  components: {
    RightOutlined,
    LeftOutlined,
    ButtonSelectCarouselOption,
  },
  props: {
    options: {
      type: Array as PropType<ButtonSelectCarouselOptionData[]>,
      required: true,
    },
    extraOptions: Array as PropType<ButtonSelectCarouselOptionData[]>,
    value: String,
    maxItems: {
      type: Number,
      default: 6,
    },
    itemMinWidth: {
      type: Number,
      default: 140,
    },
  },
  emits: ["update:value", "on-change"],
  setup(props) {
    const { t } = useI18n();
    const formValue = useVModel(props, "value");

    const handleOptionClick = (
      selectedOption: ButtonSelectCarouselOptionData
    ) => {
      const currentlySelected = selectedOption.value == formValue.value;
      const currentlySelectedValue = selectedOption.value.toString();

      // Select/Deselect values
      if (currentlySelected) {
        formValue.value = undefined;
      } else {
        formValue.value = currentlySelectedValue;
      }
    };

    // Responsive slidesToShow
    const buttonMinWidth = ref(props.itemMinWidth); // in px
    const slidesToShow = ref(props.maxItems);
    const carouselRef = ref<HTMLElement | undefined>();

    const calculateSlidesToShow = () => {
      if (carouselRef.value) {
        slidesToShow.value = Math.min(
          6,
          Math.floor(window.innerWidth / buttonMinWidth.value)
        );
      }
    };

    const onWindowResize = throttle(calculateSlidesToShow, 500);

    onMounted(() => {
      nextTick(() => {
        window.addEventListener("resize", onWindowResize);
      });
    });

    watch([props.options, props.extraOptions], () => {
      calculateSlidesToShow();
    });

    watchEffect(() => {
      calculateSlidesToShow();
    });

    return {
      t,
      formValue,
      handleOptionClick,
      slidesToShow,
      carouselRef,
      allOptions: computed<ButtonSelectCarouselOptionData[]>(() =>
        (props.extraOptions ?? [])
          .concat(props.options)
          .filter((option) => !option.hidden)
      ),
    };
  },
  methods: {},
});
