import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "header-search" }
const _hoisted_2 = { class: "header-search__content" }
const _hoisted_3 = { class: "header-search__form" }
const _hoisted_4 = {
  key: 0,
  class: "header-search__recommendations"
}
const _hoisted_5 = { class: "flex align-baseline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MovingLetters = _resolveComponent("MovingLetters")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_InputSearch = _resolveComponent("InputSearch")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CaretUpOutlined = _resolveComponent("CaretUpOutlined")!
  const _component_ButtonSelectCarousel = _resolveComponent("ButtonSelectCarousel")!
  const _component_HeaderSearchBackground = _resolveComponent("HeaderSearchBackground")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_typography_title, {
        level: 2,
        class: "text-center mb-8"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_i18n_t, {
            keypath: "Hey you, what's your {0}",
            tag: "span"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MovingLetters, {
                loop: true,
                text: [_ctx.t('product'), _ctx.t('idea'), _ctx.t('industry')],
                suffix: "?",
                "exit-delay": 3000
              }, null, 8, ["text"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FormGroup, {
          model: _ctx.formState,
          loading: _ctx.searchOnKeystroke ? false : _ctx.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputSearch, {
              value: _ctx.formState.search,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.search) = $event)),
              placeholder: _ctx.t('food, clothing, trendy, etc.'),
              options: _ctx.filterOptions,
              "on-search": _ctx.onSearch,
              "on-select": _ctx.replaceSearch,
              "on-enter-button": _ctx.onEnterButton,
              loading: _ctx.searchOnKeystroke ? false : _ctx.loading,
              "enter-button": ""
            }, null, 8, ["value", "placeholder", "options", "on-search", "on-select", "on-enter-button", "loading"])
          ]),
          _: 1
        }, 8, ["model", "loading"]),
        (_ctx.recommendations.length && _ctx.showRecommendations)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recommendations, (recommendation) => {
                return (_openBlock(), _createBlock(_component_a_button, {
                  type: "link",
                  key: recommendation.id,
                  onClick: ($event: any) => (_ctx.replaceSearch(recommendation.keyword))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(recommendation.keyword), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ButtonSelectCarousel, {
        options: _ctx.typesWithIconOptions,
        "extra-options": _ctx.extraCarouselOptions,
        value: _ctx.selectedType,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedType) = $event))
      }, {
        activeItemExtra: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_CaretUpOutlined, { class: "header-search__active-type-icon" })
          ])
        ]),
        _: 1
      }, 8, ["options", "extra-options", "value"])
    ]),
    _createVNode(_component_HeaderSearchBackground)
  ]))
}