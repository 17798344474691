import gql from "graphql-tag";

export const userSuggestionGql = gql`
  query UserSuggestion {
    userSuggestion {
      ... on UserSuggestion {
        user {
          id
          nickname
          username
        }
        videoIdeas {
          id
          title
          thumbnail
          shortDescription
          longDescription
          isActive
          script
          videoUrls
          sortWeight
          types {
            id
            name
          }
          purposes {
            id
            name
          }
          audiences {
            id
            name
          }
          industries {
            id
            name
          }
          duration
          created
        }
      }

      ... on ResponseErrors {
        __typename
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
