import { useQuery } from "@vue/apollo-composable";
import { recentlyUsedVideoIdeasGql } from "@/api/video/recentlyUsedVideoIdeas";
import {
  RecentlyUsedVideoIdeas,
  RecentlyUsedVideoIdeasVariables,
  RecentlyUsedVideoIdeas_recentlyUsedVideoIdeas,
  RecentlyUsedVideoIdeas_recentlyUsedVideoIdeas_VideoIdeaResults,
} from "@/api/video/__generated__/RecentlyUsedVideoIdeas";
import { parseGqlResponse } from "../utils/graphql/responseParser";
import { computed, reactive, ref } from "vue";
import get from "lodash/get";
import { RecentlyUsedVideoIdeasInput } from "@/../__generated__/globalTypes";
import { DEFAULT_PAGE_SIZE } from "@/shared/utils/constants";

export const useRecentlyUsedVideoIdeas = (pageSize = DEFAULT_PAGE_SIZE) => {
  const searchFilter = reactive<RecentlyUsedVideoIdeasInput>({
    pagination: {
      after: null,
      pageSize: DEFAULT_PAGE_SIZE,
    },
  });

  const {
    result: recentlyUsedVideoIdeasResult,
    loading: recentlyUsedVideoIdeasLoading,
    refetch: recentlyUsedVideoIdeasRefetch,
    fetchMore,
  } = useQuery<RecentlyUsedVideoIdeas, RecentlyUsedVideoIdeasVariables>(
    recentlyUsedVideoIdeasGql,
    () => ({
      input: searchFilter,
    }),
    () => ({
      fetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    })
  );

  // parsed data from backend
  const recentlyUsedVideoIdeasParsedData = computed(() => {
    return parseGqlResponse<RecentlyUsedVideoIdeas_recentlyUsedVideoIdeas_VideoIdeaResults>(
      "VideoIdeaResults",
      recentlyUsedVideoIdeasResult.value
    ).data;
  });

  const recentlyUsedVideoIdeasPageInfo = computed(
    () => recentlyUsedVideoIdeasParsedData.value?.pageInfo
  );

  const recentlyUsedVideoIdeasFetchMoreLoading = ref(false);
  const recentlyUsedVideoIdeasFetchMore = async () => {
    // Only proceed if has next page and theres an end cursor
    if (
      recentlyUsedVideoIdeasPageInfo.value?.hasNextPage &&
      recentlyUsedVideoIdeasPageInfo.value.endCursor
    ) {
      // set loading, use this to determine if load more is called
      recentlyUsedVideoIdeasFetchMoreLoading.value = true;

      await fetchMore({
        variables: {
          input: {
            ...searchFilter,
            pagination: {
              after: recentlyUsedVideoIdeasPageInfo.value.endCursor,
              pageSize: pageSize,
            },
          },
        },
        /**
         * manually update graphql query result
         *
         * this could easily fail because types used in union are incompatible
         * add type assertion to manually update the result type
         */
        updateQuery: (prevResult, { fetchMoreResult }) => {
          /**
           * Clone the previous result then concatenate the fetch more result
           */
          const updatedRecentlyUsedVideoIdeas = {
            ...prevResult.recentlyUsedVideoIdeas,
            // use fetchMoreResult page info but use previous as fallback
            pageInfo: get(
              fetchMoreResult?.recentlyUsedVideoIdeas,
              "pageInfo",
              prevResult?.recentlyUsedVideoIdeas?.["pageInfo"]
            ),
            // concatenate previous result and fetch more results
            results: [
              ...get(prevResult?.recentlyUsedVideoIdeas, "results", []),
              ...get(fetchMoreResult?.recentlyUsedVideoIdeas, "results", []),
            ],

            // use new total count
            totalCount: get(
              fetchMoreResult?.recentlyUsedVideoIdeas,
              "totalCount",
              prevResult?.recentlyUsedVideoIdeas?.["totalCount"]
            ),
            __typename: prevResult.recentlyUsedVideoIdeas?.__typename,
          };

          return {
            recentlyUsedVideoIdeas:
              updatedRecentlyUsedVideoIdeas as RecentlyUsedVideoIdeas_recentlyUsedVideoIdeas,
          };
        },
      });

      recentlyUsedVideoIdeasFetchMoreLoading.value = false; // reset loading
    }
  };

  const recentlyUsedVideoIdeasFetch = async () => {
    await recentlyUsedVideoIdeasRefetch();
  };

  return {
    recentlyUsedVideoIdeas: computed(
      () => recentlyUsedVideoIdeasParsedData.value?.results ?? []
    ),
    recentlyUsedVideoIdeasLoading,
    recentlyUsedVideoIdeasFetch,
    recentlyUsedVideoIdeasFetchMore,
    recentlyUsedVideoIdeasFetchMoreLoading,
    recentlyUsedVideoIdeasHasNextPage: computed(
      () => recentlyUsedVideoIdeasPageInfo.value?.hasNextPage
    ),
  };
};
