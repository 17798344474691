import gql from "graphql-tag";

export const popularVideoIdeasGql = gql`
  query PopularVideoIdeas {
    popularVideoIdeas {
      ... on PopularVideoIdeasResults {
        results {
          id
          title
          thumbnail
          duration
          created
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
