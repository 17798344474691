import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_VideoGrid = _resolveComponent("VideoGrid")!

  return (_ctx.userSuggestionLoading || !_ctx.isEmpty(_ctx.suggestedVideoIdeas))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_typography_title, { level: 5 }, {
          default: _withCtx(() => [
            (_ctx.forNewOrderWithWriterAddon)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.t("🎁 Content Writer Suggestions")), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.t("🎁 Selected videos just for you")), 1)
                ], 64))
          ]),
          _: 1
        }),
        _createVNode(_component_VideoGrid, {
          "video-ideas": _ctx.suggestedVideoIdeas,
          loading: _ctx.userSuggestionLoading,
          onOnItemClick: _ctx.handleVideoClick
        }, null, 8, ["video-ideas", "loading", "onOnItemClick"])
      ]))
    : _createCommentVNode("", true)
}