
import { SearchKeywords_searchKeywords_SearchKeywordResults_results } from "@/api/searchKeywords/__generated__/SearchKeywords";
import ButtonSelectCarousel from "@/shared/components/ButtonSelectCarousel/ButtonSelectCarousel.vue";
import { ButtonSelectCarouselOptionData } from "@/shared/components/ButtonSelectCarousel/ButtonSelectCarouselOption.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputSearch from "@/shared/components/Forms/InputSearch.vue";
import HeaderSearchBackground from "@/shared/components/HeaderSearchBackground.vue";
import { useFilterOptions } from "@/shared/composables/useFilterOptions";
import { useRecentlyUsedVideoIdeas } from "@/shared/composables/useRecentlyUsedVideoIdeas";
import { useUserSuggestion } from "@/shared/composables/useUserSuggestion";
import { SEARCH_DEBOUNCE_INTERVAL } from "@/shared/utils/constants";
import { CaretUpOutlined } from "@ant-design/icons-vue";
import { debounce } from "lodash";
import {
  PropType,
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useVModel } from "vue-composable";
import { useI18n } from "vue-i18n";
import MovingLetters from "./MovingLetters.vue";

/** List of values to not emit on parent component (not valid Video Type ID) */
export const ExtraOptionValues = {
  FOR_YOU: "FOR_YOU",
  RECENT: "RECENT",
};
const EXTRA_OPTION_VALUES = Object.values(ExtraOptionValues);

export default defineComponent({
  setup(props, { emit }) {
    const { t } = useI18n();
    const isForYouSelected = useVModel(props, "isForYouSelected");
    const isRecentTemplatesSelected = useVModel(
      props,
      "isRecentTemplatesSelected"
    );

    // Button Select Carousel Options
    const { typesWithIconOptions } = useFilterOptions();
    const { suggestedVideoIdeas } = useUserSuggestion();
    const { recentlyUsedVideoIdeas } = useRecentlyUsedVideoIdeas();

    const extraCarouselOptions = computed<ButtonSelectCarouselOptionData[]>(
      () => [
        {
          title: t("For You"),
          iconName: "for_you",
          value: ExtraOptionValues.FOR_YOU,
          hidden: !suggestedVideoIdeas.value.length,
        },
        {
          title: t("Ordered Videos"),
          iconName: "recent_templates",
          value: ExtraOptionValues.RECENT,
          hidden: !recentlyUsedVideoIdeas.value.length,
        },
      ]
    );

    const selectedType = ref("");

    /** Internal form state is required so InputSearch
     * can have separate value from the actual search in gql query.
     *
     * Useful when typing without triggering search gql query every keystroke
     */
    const internalFormState = reactive({
      search: "",
    });

    const filterOptions = computed(() => {
      // TODO: Get autocomplete options in future ticket
      return [];
    });

    // Functions
    const replaceSearch = (term: string) => {
      internalFormState.search = term;
      emit("on-search", internalFormState.search);
    };

    // Handlers
    const onSearch = props.searchOnKeystroke
      ? debounce(() => {
          emit("on-search", internalFormState.search);
        }, SEARCH_DEBOUNCE_INTERVAL)
      : null;

    const onEnterButton = () => {
      emit("on-search", internalFormState.search);
    };

    watch(selectedType, (newValue) => {
      // Only emit value if the selected value is not FOR_YOU (not a valid `typeId`)
      if (EXTRA_OPTION_VALUES.includes(newValue)) {
        emit("on-select-video-type", null);
      } else {
        emit("on-select-video-type", newValue);
      }

      isForYouSelected.value = newValue === ExtraOptionValues.FOR_YOU;
      isRecentTemplatesSelected.value = newValue === ExtraOptionValues.RECENT;
    });

    watchEffect(() => {
      internalFormState.search = props.searchTerm ?? "";
    });

    return {
      t,
      formState: internalFormState,
      onSearch,
      onEnterButton,
      replaceSearch,
      filterOptions,
      typesWithIconOptions,
      selectedType,
      extraCarouselOptions,
    };
  },
  components: {
    InputSearch,
    FormGroup,
    HeaderSearchBackground,
    MovingLetters,
    ButtonSelectCarousel,
    CaretUpOutlined,
  },
  props: {
    recommendations: {
      type: Array as PropType<
        SearchKeywords_searchKeywords_SearchKeywordResults_results[]
      >,
      default: () => [],
    },
    showRecommendations: {
      type: Boolean,
      default: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    videoType: {
      type: String,
      required: true,
    },
    searchOnKeystroke: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
    isForYouSelected: Boolean,
    isRecentTemplatesSelected: Boolean,
  },
  emits: ["on-search", "on-select-video-type", "on-click-for-you"],
});
