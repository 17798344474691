
import VideoGrid from "@/shared/components/Videos/VideoGrid.vue";
import { useRecentlyUsedVideoIdeas } from "@/shared/composables/useRecentlyUsedVideoIdeas";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import isEmpty from "lodash/isEmpty";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    hideLoadMore: Boolean,
    autoLoadMore: Boolean,
  },
  setup() {
    const { t } = useI18n();
    const { redirectToVideoDetails } = useRedirectHelper();

    const {
      recentlyUsedVideoIdeas,
      recentlyUsedVideoIdeasLoading,
      recentlyUsedVideoIdeasFetchMore,
      recentlyUsedVideoIdeasFetchMoreLoading,
      recentlyUsedVideoIdeasHasNextPage,
    } = useRecentlyUsedVideoIdeas();

    const handleVideoClick = (id: string) => {
      redirectToVideoDetails({
        videoId: id,
      });
    };

    return {
      t,
      isEmpty,

      recentlyUsedVideoIdeas,
      recentlyUsedVideoIdeasLoading,
      recentlyUsedVideoIdeasFetchMore,
      recentlyUsedVideoIdeasFetchMoreLoading,
      recentlyUsedVideoIdeasHasNextPage,

      handleVideoClick,
    };
  },
  components: { VideoGrid },
});
