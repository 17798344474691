import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "flex flex-col md:flex-row gap-4" }
const _hoisted_4 = { class: "search-results-layout__dropdown--length" }
const _hoisted_5 = { class: "search-results-layout__dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_CaretDownOutlined = _resolveComponent("CaretDownOutlined")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_SearchResult = _resolveComponent("SearchResult")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    class: "search-results-layout",
    title: _ctx.t('What kind of videos do you like?')
  }, _createSlots({
    "title-action": _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_a_typography_text, {
            class: "block mb-2",
            strong: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Length")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_InputDropdown, {
            value: _ctx.selectedVideoLengthKey,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedVideoLengthKey) = $event)),
            options: _ctx.videoLengthOptions,
            size: "small",
            placeholder: _ctx.t('All')
          }, {
            suffixIcon: _withCtx(() => [
              _createVNode(_component_CaretDownOutlined)
            ]),
            _: 1
          }, 8, ["value", "options", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_a_typography_text, {
            class: "block mb-2",
            strong: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Industry")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_InputDropdown, {
            name: "industry",
            value: _ctx.searchFilterModel.industryId,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchFilterModel.industryId) = $event)),
            options: _ctx.industryOptions,
            size: "small",
            placeholder: _ctx.t('All')
          }, {
            suffixIcon: _withCtx(() => [
              _createVNode(_component_CaretDownOutlined)
            ]),
            _: 1
          }, 8, ["value", "options", "placeholder"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_space, {
        direction: "vertical",
        size: 50,
        class: "w-full mt-6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SearchResult, {
            loadMoreButton: "",
            searchFilter: _ctx.searchFilter,
            results: _ctx.videoIdeasList,
            loading: _ctx.videoIdeasLoading,
            fetchMoreLoading: _ctx.videoIdeasFetchMoreLoading,
            hasNextPage: _ctx.videoIdeasHasNextPage,
            onOnLoadMore: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-load-more'))),
            autoLoadMore: _ctx.autoLoadMore
          }, null, 8, ["searchFilter", "results", "loading", "fetchMoreLoading", "hasNextPage", "autoLoadMore"])
        ]),
        _: 1
      })
    ]),
    _: 2
  }, [
    (!_ctx.videoIdeasLoading)
      ? {
          name: "subtitle",
          fn: _withCtx(() => [
            (_ctx.isEmpty(_ctx.videoIdeasList) && _ctx.searchFilterModel.query)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.t(`Your keyword '{searchKey}' doesn't have results.`, {
            searchKey: _ctx.searchFilterModel.query,
          })), 1))
              : (!_ctx.isEmpty(_ctx.videoIdeasList) && _ctx.searchFilterModel.query)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                    _createVNode(_component_i18n_t, {
                      tag: "span",
                      keypath: "We listed the usual top-performing videos for {searchKey} brands."
                    }, {
                      searchKey: _withCtx(() => [
                        _createVNode(_component_a_typography_text, { strong: "" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.searchFilterModel.query), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" " + _toDisplayString(!!_ctx.maxNumberOfVideosHint
            ? _ctx.t("Pick {numOfVideos} to get started!", {
                numOfVideos: _ctx.maxNumberOfVideosHint,
              })
            : _ctx.t("Pick your favorites to get started!")), 1)
                  ]))
                : _createCommentVNode("", true)
          ])
        }
      : undefined
  ]), 1032, ["title"]))
}