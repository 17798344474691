import gql from "graphql-tag";

export const searchKeywordsGql = gql`
  query SearchKeywords {
    searchKeywords {
      ... on SearchKeywordResults {
        results {
          id
          keyword
          sortWeight
          isActive
        }
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
