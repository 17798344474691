
import { defineComponent, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import VideoGrid from "@/shared/components/Videos/VideoGrid.vue";
import UserSuggestions from "@/shared/components/UserSuggestions.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { useOrders } from "@/shared/composables/useOrders";
import PerformerGrid from "@/shared/components/Performers/PerformerGrid.vue";
import { useRecommendedVideoIdeas } from "@/shared/composables/useRecommendedVideoIdeas";
import { usePopularVideoIdeas } from "@/shared/composables/usePopularVideoIdeas";
import { useRecommendedPerformers } from "@/shared/composables/useRecommendedPerformers";
import isEmpty from "lodash/isEmpty";
import { DEFAULT_PAGE_SIZE } from "@/shared/utils/constants";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import RecentlyUsedVideoIdeas from "@/shared/components/RecentlyUsedVideoIdeas.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const {
      redirectToVideoDetails,
      redirectToPerformerDetails,
      redirectToHomepage,
    } = useRedirectHelper();

    const { orders, ordersLoading } = useOrders({ hideError: true });
    const ordersItems = computed(() =>
      orders.value?.map((order) => order?.items).flatMap((items) => items)
    );

    const {
      recommendedVideoIdeas: trendyVideos,
      recommendedVideoIdeasLoading: trendyVideosLoading,
    } = useRecommendedVideoIdeas();

    const { popularVideoIdeas, popularVideoIdeasLoading } =
      usePopularVideoIdeas();

    const {
      recommendedPerformers: topPerformers,
      recommendedPerformersLoading: topPerformersLoading,
    } = useRecommendedPerformers({ includePremium: true });

    // === Recently Used Templates ===
    const recentTemplatesShownCount = ref(DEFAULT_PAGE_SIZE);

    const recentTemplatesHasNextPage = computed(
      () => recentTemplatesShownCount.value < (orders.value?.length ?? 0)
    );

    const recentTemplates = computed(() => {
      return (
        // Remove duplicate values
        Array.from(new Set(ordersItems.value ?? []))
          // Then slice depending on current total shown count
          .slice(0, recentTemplatesShownCount.value)
      );
    });

    const handleRecentTemplatesLoadMore = () => {
      recentTemplatesShownCount.value += DEFAULT_PAGE_SIZE;
    };

    const handleVideoClick = (id: string) => {
      redirectToVideoDetails({ videoId: id });
    };

    const handlePerformerClick = (id: string) => {
      redirectToPerformerDetails({ id: id });
    };

    const handleRecentlyUsedLoadMore = () => {
      redirectToHomepage({ layout: "RECENT" });
    };

    return {
      t,
      isEmpty,

      topPerformers,
      topPerformersLoading,
      recentTemplates,
      recentTemplatesHasNextPage,
      recentTemplatesLoading: ordersLoading,
      handleRecentTemplatesLoadMore,
      handleVideoClick,
      trendyVideos,
      trendyVideosLoading,
      popularVideoIdeas,
      popularVideoIdeasLoading,
      handlePerformerClick,
      handleRecentlyUsedLoadMore,
    };
  },
  components: {
    VideoGrid,
    PageLayout,
    PerformerGrid,
    UserSuggestions,
    RecentlyUsedVideoIdeas,
  },
});
