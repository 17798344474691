import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "moving-letters",
  ref: "movingLettersContainerRef"
}
const _hoisted_2 = {
  key: 0,
  class: "moving-letters__letter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.words.list, (word, i) => {
      return (_openBlock(), _createElementBlock("span", {
        class: _normalizeClass(["moving-letters__word", [`moving-letters__word-${i}`]]),
        key: word
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(word, (letter, i) => {
          return (_openBlock(), _createElementBlock("span", {
            class: "moving-letters__letter",
            key: letter + i
          }, _toDisplayString(letter), 1))
        }), 128)),
        (_ctx.suffix)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.suffix), 1))
          : _createCommentVNode("", true)
      ], 2))
    }), 128))
  ], 512))
}