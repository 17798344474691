import { userSuggestionGql } from "@/api/filters/userSuggestion";
import {
  UserSuggestion,
  UserSuggestion_userSuggestion_UserSuggestion,
} from "@/api/filters/__generated__/UserSuggestion";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import { computed } from "vue";

export const useUserSuggestion = () => {
  const { result: userSuggestionResult, loading: userSuggestionLoading } =
    useQuery<UserSuggestion>(userSuggestionGql);

  const userSuggestion = computed(() => {
    return parseGqlResponse<UserSuggestion_userSuggestion_UserSuggestion>(
      "UserSuggestion",
      userSuggestionResult.value
    ).data;
  });

  const suggestedVideoIdeas = computed(
    () => userSuggestion.value?.videoIdeas ?? []
  );

  return { userSuggestion, userSuggestionLoading, suggestedVideoIdeas };
};
