import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-search__bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LayoutIcon, {
      name: "lines",
      class: "lines"
    }),
    _createVNode(_component_LayoutIcon, {
      name: "dotsHalfHeightYellow",
      class: "dots-1"
    }),
    _createVNode(_component_LayoutIcon, {
      name: "dotsHalfHeightYellow",
      class: "dots-2"
    })
  ]))
}