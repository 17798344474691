import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createBlock(_component_a_image, {
    class: "dynamic-app-icon",
    src: _ctx.getAppIcon(_ctx.name) ?? _ctx.getAppIcon(_ctx.fallbackName) ?? '',
    fallback: _ctx.fallbackTransparentSrc
  }, null, 8, ["src", "fallback"]))
}