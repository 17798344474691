import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserSuggestions = _resolveComponent("UserSuggestions")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_VideoGrid = _resolveComponent("VideoGrid")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_RecentlyUsedVideoIdeas = _resolveComponent("RecentlyUsedVideoIdeas")!
  const _component_PerformerGrid = _resolveComponent("PerformerGrid")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, { class: "recommendation-layout" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_space, {
        size: 64,
        direction: "vertical",
        class: "mt-16 w-full"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UserSuggestions),
          (_ctx.popularVideoIdeasLoading || !_ctx.isEmpty(_ctx.popularVideoIdeas))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_a_typography_title, { level: 5 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("🎉 Viral and Popular")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_VideoGrid, {
                  "video-ideas": _ctx.popularVideoIdeas,
                  loading: _ctx.popularVideoIdeasLoading,
                  onOnItemClick: _ctx.handleVideoClick
                }, null, 8, ["video-ideas", "loading", "onOnItemClick"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.trendyVideosLoading || !_ctx.isEmpty(_ctx.trendyVideos))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_a_typography_title, { level: 5 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("🔥 Hot and Trendy")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_VideoGrid, {
                  "video-ideas": _ctx.trendyVideos,
                  loading: _ctx.trendyVideosLoading,
                  onOnItemClick: _ctx.handleVideoClick
                }, null, 8, ["video-ideas", "loading", "onOnItemClick"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_RecentlyUsedVideoIdeas, { "hide-load-more": "" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_button, {
                  onClick: _ctx.handleRecentlyUsedLoadMore,
                  class: "mt-4"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("See all")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          }),
          (_ctx.topPerformersLoading || !_ctx.isEmpty(_ctx.topPerformers))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_a_typography_title, { level: 5 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("💃 Popular Model Creators")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PerformerGrid, {
                  "performers-list": _ctx.topPerformers ?? [],
                  loading: _ctx.topPerformersLoading,
                  onOnItemClick: _ctx.handlePerformerClick
                }, null, 8, ["performers-list", "loading", "onOnItemClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}