
import { computed, defineComponent } from "vue";
import { useUserSuggestion } from "@/shared/composables/useUserSuggestion";
import { useLatestQuotation } from "@/shared/composables/useLatestQuotation";
import VideoGrid from "@/shared/components/Videos/VideoGrid.vue";
import { useI18n } from "vue-i18n";
import isEmpty from "lodash/isEmpty";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { redirectToVideoDetails } = useRedirectHelper();

    const { suggestedVideoIdeas, userSuggestion, userSuggestionLoading } =
      useUserSuggestion();

    const { latestQuotation } = useLatestQuotation();

    const handleVideoClick = (id: string) => {
      redirectToVideoDetails({
        videoId: id,
      });
    };

    const forNewOrderWithWriterAddon = computed<boolean>(
      () =>
        !latestQuotation.value?.order && !!latestQuotation.value?.hireStrategist
    );

    return {
      t,
      isEmpty,

      forNewOrderWithWriterAddon,
      suggestedVideoIdeas,
      userSuggestion,
      userSuggestionLoading,
      handleVideoClick,
    };
  },
  components: { VideoGrid },
});
