import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_VideoGrid = _resolveComponent("VideoGrid")!

  return (_ctx.recentlyUsedVideoIdeasLoading || !_ctx.isEmpty(_ctx.recentlyUsedVideoIdeas))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_typography_title, { level: 5 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("🕑 Recently Used Templates")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_VideoGrid, {
          "video-ideas": _ctx.recentlyUsedVideoIdeas,
          loading: _ctx.recentlyUsedVideoIdeasLoading,
          "has-next-page": !_ctx.hideLoadMore && _ctx.recentlyUsedVideoIdeasHasNextPage,
          "load-more-loading": _ctx.recentlyUsedVideoIdeasFetchMoreLoading,
          onOnLoadMore: _ctx.recentlyUsedVideoIdeasFetchMore,
          onOnItemClick: _ctx.handleVideoClick,
          autoLoadMore: _ctx.autoLoadMore
        }, null, 8, ["video-ideas", "loading", "has-next-page", "load-more-loading", "onOnLoadMore", "onOnItemClick", "autoLoadMore"]),
        _renderSlot(_ctx.$slots, "default")
      ]))
    : _createCommentVNode("", true)
}