
import HeaderSearch from "@/shared/components/HeaderSearch.vue";
import SearchResultLayout from "@/web/views/Home/SearchResultsLayout.vue";
import RecommendationsLayout from "@/web/views/Home/RecommendationsLayout.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import UserSuggestions from "@/shared/components/UserSuggestions.vue";
import { useSearchKeywords } from "@/shared/composables/useSearchKeywords";
import { useVideoIdeas } from "@/shared/composables/useVideoIdeas";
import routeNames from "@/web/router/routeNames";
import isEmpty from "lodash/isEmpty";
import { computed, defineComponent, ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useSubscriptionPlans } from "@/shared/composables/useSubscriptionPlans";
import {
  useRedirectHelper,
  HomeLayout,
} from "@/shared/composables/useRedirectHelper";
import RecentlyUsedVideoIdeas from "@/shared/components/RecentlyUsedVideoIdeas.vue";
import { useHowToOrder } from "@/shared/composables/useHowToOrder";
import { scrollToElement } from "@/shared/utils/browser";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";

export default defineComponent({
  // For keep-alive
  name: routeNames.home,
  props: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const { redirectToHomepage } = useRedirectHelper();
    const { isMobile } = useWindowSizeChecker();

    const showForYouPage = ref(false);
    const showRecentTemplatesPage = ref(false);

    /** Used to dictate which layout to use, Recommendations or SearchResults */
    const homeLayout = computed<HomeLayout>(() => {
      const layout = String(route.query.view ?? "") as HomeLayout;
      const search = String(route.query.search ?? "");

      if (showForYouPage.value) {
        return "FOR_YOU";
      }

      if (showRecentTemplatesPage.value) {
        return "RECENT";
      }

      if (
        layout === "SEARCH" ||
        search ||
        searchFilter.query ||
        searchFilter.typeId
      ) {
        return "SEARCH";
      }

      return layout || "HOME";
    });

    const {
      searchFilter,
      videoIdeasList,
      videoIdeasLoading,
      videoIdeasFetchMoreLoading,
      videoIdeasFetchMore,
      videoIdeasHasNextPage,
    } = useVideoIdeas();
    const { currentSubscriptionPlan } = useSubscriptionPlans();

    const onSearch = (searchKey: string) => {
      redirectToHomepage({
        search: searchKey,
        // Used only to prevent vue-router's default hash
        hash: "#",
      });

      if (isMobile.value) {
        setTimeout(() => {
          scrollToElement("home-page__content");
        }, 50);
      }
    };

    const doSearch = (searchKey: string) => {
      searchFilter.query = searchKey.trim();
    };

    const handleLoadMore = async () => {
      await videoIdeasFetchMore();
    };

    // Suggested Keywords
    const { searchKeywords } = useSearchKeywords();

    const handleSelectVideoType = (videoType) => {
      searchFilter.typeId = videoType;
    };

    // Handles the actual searching via route changes
    watchEffect(() => {
      const search: string = route.query["search"]?.toString() ?? "";
      doSearch(search);
    });

    const updateLayoutRefs = (view: string) => {
      showForYouPage.value = view === "FOR_YOU";
      showRecentTemplatesPage.value = view === "RECENT";
    };

    watch(route.query, (query) => {
      if (query?.view) {
        updateLayoutRefs(String(query.view));
      }
    });

    const { checkIfFirstLogin } = useHowToOrder();
    checkIfFirstLogin();

    return {
      t,
      searchKeywords,
      searchFilter,
      videoIdeasList,
      videoIdeasLoading,
      videoIdeasHasNextPage,
      onSearch,
      handleLoadMore,
      videoIdeasFetchMoreLoading,
      homeLayout,
      currentSubscriptionPlan,
      handleSelectVideoType,
      showForYouPage,
      showRecentTemplatesPage,
    };
  },
  methods: {
    isEmpty,
  },
  components: {
    HeaderSearch,
    SearchResultLayout,
    RecommendationsLayout,
    UserSuggestions,
    PageLayout,
    RecentlyUsedVideoIdeas,
  },
});
