import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftOutlined = _resolveComponent("LeftOutlined")!
  const _component_RightOutlined = _resolveComponent("RightOutlined")!
  const _component_ButtonSelectCarouselOption = _resolveComponent("ButtonSelectCarouselOption")!
  const _component_a_carousel = _resolveComponent("a-carousel")!

  return (_openBlock(), _createBlock(_component_a_carousel, {
    ref: "carouselRef",
    class: "button-select-carousel select-none",
    arrows: "",
    infinite: false,
    dots: false,
    slidesToShow: _ctx.slidesToShow,
    key: _ctx.allOptions.length
  }, {
    prevArrow: _withCtx(() => [
      _createVNode(_component_LeftOutlined, { class: "button-select-carousel__slick" })
    ]),
    nextArrow: _withCtx(() => [
      _createVNode(_component_RightOutlined, { class: "button-select-carousel__slick" })
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allOptions, (option) => {
        return (_openBlock(), _createBlock(_component_ButtonSelectCarouselOption, {
          key: option.value,
          option: option,
          "selected-value": _ctx.formValue,
          onOnSelectOption: ($event: any) => (_ctx.handleOptionClick(option))
        }, _createSlots({ _: 2 }, [
          (_ctx.$slots['activeItemExtra'])
            ? {
                name: "activeItemExtra",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "activeItemExtra")
                ])
              }
            : undefined
        ]), 1032, ["option", "selected-value", "onOnSelectOption"]))
      }), 128))
    ]),
    _: 3
  }, 8, ["slidesToShow"]))
}