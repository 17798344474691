
import { VideoIdeasInput } from "@/../__generated__/globalTypes";
import { VideoIdeas_videoIdeas_VideoIdeaResults_results } from "@/api/video/__generated__/VideoIdeas";
import InputDropdown from "@/shared/components/Forms/InputDropdown.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import SearchResult from "@/shared/components/Videos/SearchResults.vue";
import CaretDownOutlined from "@ant-design/icons-vue/CaretDownOutlined";
import isEmpty from "lodash/isEmpty";
import { defineComponent, PropType, ref, watch } from "vue";
import { useVModel } from "vue-composable";
import { useI18n } from "vue-i18n";
import { useFilterOptions } from "@/shared/composables/useFilterOptions";
import { VideoIdeasVideoIdeaDurationChoices } from "@/../__generated__/globalTypes";
import { videoLengthOptions } from "@/shared/utils/video";

export default defineComponent({
  props: {
    videoIdeasLoading: {
      type: Boolean,
      default: false,
    },
    videoIdeasFetchMoreLoading: {
      type: Boolean,
      default: false,
    },
    videoIdeasHasNextPage: Boolean,
    videoIdeasList: {
      type: Array as PropType<VideoIdeas_videoIdeas_VideoIdeaResults_results[]>,
      default: () => [],
    },
    searchFilter: {
      type: Object as PropType<VideoIdeasInput>,
      required: true,
    },
    maxNumberOfVideosHint: {
      type: Number,
    },
    autoLoadMore: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const searchFilterModel = useVModel(props, "searchFilter");

    const { industryDropdownOptions } = useFilterOptions();

    const selectedVideoLengthKey =
      ref<VideoIdeasVideoIdeaDurationChoices | null>(null);

    watch(selectedVideoLengthKey, (value) => {
      searchFilterModel.value.duration = value;
    });

    return {
      t,
      searchFilterModel,
      industryOptions: industryDropdownOptions,
      videoLengthOptions,
      selectedVideoLengthKey,
    };
  },
  methods: {
    isEmpty,
  },
  components: {
    InputDropdown,
    PageLayout,
    SearchResult,
    CaretDownOutlined,
  },
  emits: ["on-load-more"],
});
