import { searchKeywordsGql } from "@/api/searchKeywords/searchKeywords";
import {
  SearchKeywords,
  SearchKeywords_searchKeywords_SearchKeywordResults,
  SearchKeywords_searchKeywords_SearchKeywordResults_results,
} from "@/api/searchKeywords/__generated__/SearchKeywords";
import { useQuery } from "@vue/apollo-composable";
import { computed } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { orderBy } from "lodash";

export const useSearchKeywords = () => {
  const { result: searchKeywordsResult, loading: searchKeywordsLoading } =
    useQuery<SearchKeywords>(searchKeywordsGql);

  const searchKeywords = computed<
    SearchKeywords_searchKeywords_SearchKeywordResults_results[]
  >(() => {
    const results =
      parseGqlResponse<SearchKeywords_searchKeywords_SearchKeywordResults>(
        "SearchKeywordResults",
        searchKeywordsResult.value
      ).data?.results;

    return orderBy(results, ["sortWeight"], ["desc"]);
  });

  const firstSearchKeyword = computed(() => {
    return searchKeywords?.value[0]?.keyword;
  });

  return {
    searchKeywords,
    searchKeywordsLoading,
    firstSearchKeyword,
  };
};
