
import { defineComponent, PropType } from "vue";
import DynamicAppIcon, {
  DynamicAppIconName,
} from "@/shared/components/Icons/DynamicAppIcon.vue";

export type ButtonSelectCarouselOptionData = {
  value: string | number;
  title: string;
  iconName: DynamicAppIconName;
  hidden?: boolean;
};

export default defineComponent({
  emits: ["on-select-option"],
  props: {
    option: {
      type: Object as PropType<ButtonSelectCarouselOptionData>,
      required: true,
    },
    selectedValue: [String, Number],
  },
  setup() {},
  components: { DynamicAppIcon },
});
