import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-page" }
const _hoisted_2 = {
  id: "home-page__content",
  class: "home-page__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderSearch = _resolveComponent("HeaderSearch")!
  const _component_UserSuggestions = _resolveComponent("UserSuggestions")!
  const _component_PageLayout = _resolveComponent("PageLayout")!
  const _component_RecentlyUsedVideoIdeas = _resolveComponent("RecentlyUsedVideoIdeas")!
  const _component_SearchResultLayout = _resolveComponent("SearchResultLayout")!
  const _component_RecommendationsLayout = _resolveComponent("RecommendationsLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderSearch, {
      "is-for-you-selected": _ctx.showForYouPage,
      "onUpdate:is-for-you-selected": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showForYouPage) = $event)),
      "is-recent-templates-selected": _ctx.showRecentTemplatesPage,
      "onUpdate:is-recent-templates-selected": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showRecentTemplatesPage) = $event)),
      recommendations: _ctx.searchKeywords,
      loading: _ctx.homeLayout === 'SEARCH' && _ctx.videoIdeasLoading,
      "search-on-keystroke": false,
      "show-recommendations": false,
      "search-term": _ctx.searchFilter.query,
      onOnSearch: _ctx.onSearch,
      onOnSelectVideoType: _ctx.handleSelectVideoType
    }, null, 8, ["is-for-you-selected", "is-recent-templates-selected", "recommendations", "loading", "search-term", "onOnSearch", "onOnSelectVideoType"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.homeLayout === 'FOR_YOU')
        ? (_openBlock(), _createBlock(_component_PageLayout, {
            key: 0,
            class: _normalizeClass(['mt-16 w-full', _ctx.homeLayout])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_UserSuggestions)
            ]),
            _: 1
          }, 8, ["class"]))
        : (_ctx.homeLayout === 'RECENT')
          ? (_openBlock(), _createBlock(_component_PageLayout, {
              key: 1,
              class: _normalizeClass(['mt-16 w-full', _ctx.homeLayout])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_RecentlyUsedVideoIdeas, { "auto-load-more": "" })
              ]),
              _: 1
            }, 8, ["class"]))
          : (_ctx.homeLayout === 'SEARCH')
            ? (_openBlock(), _createBlock(_component_SearchResultLayout, {
                key: 2,
                "video-ideas-loading": _ctx.videoIdeasLoading,
                videoIdeasFetchMoreLoading: _ctx.videoIdeasFetchMoreLoading,
                videoIdeasHasNextPage: _ctx.videoIdeasHasNextPage,
                videoIdeasList: _ctx.videoIdeasList,
                searchFilter: _ctx.searchFilter,
                "max-number-of-videos-hint": _ctx.currentSubscriptionPlan?.maxVideoCount,
                onOnLoadMore: _ctx.handleLoadMore,
                "auto-load-more": ""
              }, null, 8, ["video-ideas-loading", "videoIdeasFetchMoreLoading", "videoIdeasHasNextPage", "videoIdeasList", "searchFilter", "max-number-of-videos-hint", "onOnLoadMore"]))
            : (_ctx.homeLayout === 'HOME')
              ? (_openBlock(), _createBlock(_component_RecommendationsLayout, { key: 3 }))
              : _createCommentVNode("", true)
    ])
  ]))
}