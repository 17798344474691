import { useQuery } from "@vue/apollo-composable";
import { recommendedVideoIdeasGql } from "@/api/video/recommendedVideoIdeas";
import {
  RecommendedVideoIdeas,
  RecommendedVideoIdeas_recommendedVideoIdeas_RecommendedVideoIdeasResults,
} from "@/api/video/__generated__/RecommendedVideoIdeas";
import { parseGqlResponse } from "../utils/graphql/responseParser";
import { computed } from "vue";

export const useRecommendedVideoIdeas = () => {
  const {
    result: recommendedVideoIdeasResult,
    loading: recommendedVideoIdeasLoading,
    refetch: recommendedVideoIdeasRefetch,
  } = useQuery<RecommendedVideoIdeas>(
    recommendedVideoIdeasGql,
    {},
    { fetchPolicy: "network-only" }
  );

  const videoIdeasParsedData = computed(() => {
    return parseGqlResponse<RecommendedVideoIdeas_recommendedVideoIdeas_RecommendedVideoIdeasResults>(
      "RecommendedVideoIdeasResults",
      recommendedVideoIdeasResult.value
    ).data;
  });

  const recommendedVideoIdeas = computed(
    () => videoIdeasParsedData.value?.results ?? []
  );

  return {
    recommendedVideoIdeas,
    recommendedVideoIdeasLoading,
    recommendedVideoIdeasRefetch,
  };
};
