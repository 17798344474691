
import { PropType, defineComponent } from "vue";
import bts from "@/assets/filters/bts.svg";
import educational_videos from "@/assets/filters/educational-videos.svg";
import fan_engagement from "@/assets/filters/fan-engagement.svg";
import funny_skit from "@/assets/filters/funny-skit.svg";
import get_ready_with_me from "@/assets/filters/get-ready-with-me.svg";
import how_to_video from "@/assets/filters/how-to-video.svg";
import problem_statement from "@/assets/filters/problem-statement.svg";
import product_highlight from "@/assets/filters/product-highlight.svg";
import this_or_that from "@/assets/filters/this-or-that.svg";
import tips_and_tricks from "@/assets/filters/tips-and-tricks.svg";
import trendy_videos from "@/assets/filters/trendy-videos.svg";
import ugc from "@/assets/filters/ugc.svg";
import unboxing from "@/assets/filters/unboxing.svg";
// not named `video-ad` since extensions like uBlock seems to block this resource due to "ad"
import video_ad from "@/assets/filters/videos.svg";
import aesthetic_video from "@/assets/filters/aesthetic-video.svg";
import before_after from "@/assets/filters/before-after.svg";
import brand_story from "@/assets/filters/brand-story.svg";
import educational_video from "@/assets/filters/educational-video.svg";
import honest_review from "@/assets/filters/honest-review.svg";
import informative from "@/assets/filters/informative.svg";
import pov from "@/assets/filters/pov.svg";
import review from "@/assets/filters/review.svg";
import talking_head from "@/assets/filters/talking-head.svg";
import this_is_your_sign from "@/assets/filters/this-is-your-sign.svg";
// Custom features, not from BE
import for_you from "@/assets/filters/for-you.svg";
import recent_templates from "@/assets/filters/recent-templates.svg";
import { fallbackTransparentSrc } from "@/shared/utils/constants";
import snakeCase from "lodash/snakeCase";

const DYNAMIC_ICONS = {
  bts,
  educational_videos,
  fan_engagement,
  funny_skit,
  get_ready_with_me,
  how_to_video,
  problem_statement,
  product_highlight,
  this_or_that,
  tips_and_tricks,
  trendy_videos,
  ugc,
  unboxing,
  video_ad,
  aesthetic_video,
  before_after,
  before_and_after: before_after, // alias for `&`
  brand_story,
  educational_video,
  honest_review,
  informative,
  pov,
  review,
  talking_head,
  this_is_your_sign,
  for_you,
  recent_templates,
};

/**
 * Gets icon name using snake_case. Used mainly for icons matching to backend values.
 */
export const getAppIcon = (iconName) => {
  return DYNAMIC_ICONS[snakeCase(iconName)];
};

/** Helper type to hint values from {@link DYNAMIC_ICONS}, but still accept any `string` types. */
// eslint-disable-next-line @typescript-eslint/ban-types
export type DynamicAppIconName = keyof typeof DYNAMIC_ICONS | (string & {});

export default defineComponent({
  props: {
    name: {
      type: String as PropType<DynamicAppIconName>,
      required: true,
    },
    // this is a fallback icon name if ever primary icon fails to load
    fallbackName: {
      type: String as PropType<DynamicAppIconName>,
    },
  },
  setup() {
    return { fallbackTransparentSrc };
  },
  methods: { getAppIcon },
});
