import gql from "graphql-tag";

export const recentlyUsedVideoIdeasGql = gql`
  query RecentlyUsedVideoIdeas($input: RecentlyUsedVideoIdeasInput!) {
    recentlyUsedVideoIdeas(input: $input) {
      ... on VideoIdeaResults {
        results {
          id
          title
          thumbnail
          duration
          created
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
